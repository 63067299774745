import React from 'react';
import DisplayAllTopics from "../../components/map_displays/displayAllTopics";
import HeroImage from '../../components/hero';
import "../../styling/Topics.css"
import topicsImageHeader from "../../static/backgroundHero.jpg";
import { PageLayout } from "../../components/page-layout";

function Topics() {

//return the HTML output
  return (
    <PageLayout>
    <div className="container-flex"> 
      <HeroImage imagepath={topicsImageHeader} header="Topics" subheader="View All Topics" pad='20px' ></HeroImage>           
      <br />
      <DisplayAllTopics />
    </div>
    </PageLayout>
  );
}

//export Topics
export default Topics;

