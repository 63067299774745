import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/page-layout";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import HeroImage from "../../components/hero";
import topicsImageHeader from "../../static/backgroundHero.jpg";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// Full Dyslexia Screening Test questions (18 questions)
const questions = [
  { text: "I find it difficult to read aloud." },
  { text: "I have trouble remembering sequences, such as numbers or directions." },
  { text: "I often mix up letters or numbers when writing." },
  { text: "I avoid reading aloud in class or group settings." },
  { text: "I find it hard to understand instructions given verbally." },
  { text: "I often lose my place when reading." },
  { text: "I find it difficult to organize my thoughts on paper." },
  { text: "I have trouble spelling common words." },
  { text: "I prefer to listen to information rather than read it." },
  { text: "I get tired or frustrated when reading for long periods." },
  { text: "I often skip words when reading." },
  { text: "I have difficulty summarizing what I read." },
  { text: "I often feel overwhelmed by long texts." },
  { text: "I find it easier to understand pictures or diagrams than text." },
  { text: "I frequently confuse similar-looking words." },
  { text: "I avoid reading for pleasure." },
  { text: "I often forget what I just read." },
  { text: "I have difficulty remembering names." },
  { text: "I find it hard to follow along when others are reading." },
];

const chunkedQuestions = [];
for (let i = 0; i < questions.length; i += 5) {
  chunkedQuestions.push(questions.slice(i, i + 5));
}

const DyslexiaTest = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState(Array(18).fill(null)); 
  const [showScore, setShowScore] = useState(false); 
  const [email, setEmail] = useState(""); 
  const [result, setResult] = useState(0);
  const [nowAsPercent, setNowAsPercent] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(true); 
  const [addedMailList, setAddedEmailList] = useState(false);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
    if(isAuthenticated) {
      setAddedEmailList(true)
    }
  }, [currentPage, isAuthenticated]);

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const calculateScore = () => answers.reduce((score, answer) => score + (answer || 0), 0);

  useEffect(() => {
    setNowAsPercent(Math.round((answers.filter((a) => a !== null).length / questions.length) * 100));
  }, [answers]);
  
  const allAnsweredOnPage = chunkedQuestions[currentPage].every(
    (_, index) => answers[(currentPage * 5) + index] !== null
  );
  

  const handleSubmit = () => {
    if (currentPage === chunkedQuestions.length - 1) {
      submitQuizClick();
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const submitQuizClick = () => {
    setResult(calculateScore());
    if (sessionStorage.getItem("addedEmailListDST")) setAddedEmailList(true);
    setShowScore(true);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append('email', email);

      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/users/email/subscribe`, formData);
      sessionStorage.setItem("addedEmailListDST", true);
      setAddedEmailList(true);
    } catch {
      alert("Failed to subscribe. Please try again later.");
    }
  };

  const startTest = () => setShowDisclaimer(false);

  return (
    <PageLayout>
      <HeroImage imagepath={topicsImageHeader} header="Dyslexia Assessment" subheader="Dyslexia Screening Test" pad="20px" />

      {showDisclaimer ? (
        <section style={{ textAlign: "center", margin: "10%" }}>
          <p>
            <strong>Disclaimer:</strong> This test is a screening tool, not a diagnostic assessment. For a formal diagnosis, consult a
            healthcare professional.
          </p>
          <Button onClick={startTest}>I Understand</Button>
        </section>
      ) : chunkedQuestions.length > 0 && !showScore ? (
        <section style={{ width: "95%", margin: "10px auto" }}>
          <p>"1 - Never", "2- Rarely", "3 - Sometimes", "4 - Often", "5 - Very Often"</p>
          <ProgressBar now={nowAsPercent} label={`${Math.round(nowAsPercent)}%`} />
          <div>
            {chunkedQuestions[currentPage].map((question, index) => (
              <div key={index} style={{ backgroundColor: index % 2 === 1 ? "white" : "#0d6efd36", padding: "2%" }}>
                <p>{question.text}</p>
               
               
                <div className="w-100" style={{ maxWidth: "100%", margin: "auto" }}>
                  <ButtonGroup className="w-100">
                    {["1 - Never", "2", "3", "4", "5 - Very Often"].map((label, value) => (
                      <ToggleButton
                        key={value}
                        id={`radio-${index}-${value}`}
                        type="radio"
                        name={`question-${index + currentPage * 5}`}
                        variant="outline-primary"
                        value={value}
                        checked={answers[index + currentPage * 5] === value}
                        onChange={() => handleAnswerChange(index + currentPage * 5, value)}
                        className="flex-fill text-black py-3 px-0"
                        style={{ minWidth: "18%" }}
                      >
                        {label}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between", margin: "20px auto" }}>
              <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>Previous</Button>
              <Button onClick={handleSubmit} disabled={!allAnsweredOnPage}>{currentPage === chunkedQuestions.length - 1 ? "Submit" : "Next"}</Button>
            </div>

          </div>
        </section>
      ) : (
        showScore && (
          <section style={{width: "90%", margin:"auto", padding:"10px", textAlign:"center", backgroundColor: "rgba(0,0,0,0.2)"}}>
            
            {addedMailList ? (
            <div>
            <h2>Your Score: {result} / 72</h2>
            <p>
              Scores between 0-24: Low likelihood of dyslexia.<br />
              Scores between 25-48: Moderate likelihood of dyslexia.<br />
              Scores between 49-72: High likelihood of dyslexia.
            </p>
            <p>If you scored in the higher range, consider consulting a professional for further evaluation.</p>
            </div>
            ):(
              <>
              <h3>Your Score: ?</h3>
              <p>To receive your detailed results, please join our mailing list or create an account.</p>
            
            <form onSubmit={handleFormSubmit}>
              <input
                type="email"
                placeholder="Enter your email for results"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ padding: "5px", margin: "10px" }}
              />
              <Button type="submit">Submit</Button>
            </form>
            </>
           
            )}
          </section>
        )
      )}
    </PageLayout>
  );
};

export default DyslexiaTest;
