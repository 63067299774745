import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/page-layout";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import HeroImage from "../../components/hero";
import topicsImageHeader from "../../static/backgroundHero.jpg";
import { ProgressBar } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const questions = [
  { text: "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?", reverse: false },
  { text: "How often do you have difficulty getting things in order when you have to do a task that requires organization?", reverse: false },
  { text: "How often do you have problems remembering appointments or obligations?", reverse: false },
  { text: "How often do you find yourself fidgeting with your hands or feet when you have to sit down for a long time?", reverse: false },
  { text: "How often do you feel restless or have difficulty relaxing?", reverse: false },
  { text: "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?", reverse: false },
  { text: "How often do you lose things like keys, glasses, or paperwork?", reverse: false },
  { text: "How often do you get distracted by extraneous stimuli (e.g., noises, other conversations)?", reverse: false },
  { text: "How often do you forget to complete things like chores or errands?", reverse: false },
  { text: "How often do you have trouble remaining seated in situations where it is expected?", reverse: false },
  { text: "How often do you feel overwhelmed by tasks?", reverse: false },
  { text: "How often do you talk excessively?", reverse: false },
  { text: "How often do you have difficulty waiting your turn?", reverse: false },
  { text: "How often do you interrupt or intrude on others (e.g., butting into conversations or games)?", reverse: false },
  { text: "How often do you find yourself losing track of what you were doing?", reverse: false },
  { text: "How often do you feel like you are constantly on the go?", reverse: false },
  { text: "How often do you feel like your mind is going blank?", reverse: false },
  { text: "How often do you have difficulty finishing projects or tasks?", reverse: false },
  { text: "How often do you avoid or procrastinate on tasks that require sustained mental effort?", reverse: false },
];

const chunkedQuestions = [];
for (let i = 0; i < questions.length; i += 5) {
  chunkedQuestions.push(questions.slice(i, i + 5));
}

const ADHDTest = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState(Array(18).fill(null));
  const [showScore, setShowScore] = useState(false);
  const [email, setEmail] = useState('');
  const [result, setResult] = useState({ partAScore: 0, partBScore: 0 });
  const [addedMailList, setAddedEmailList] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [showDisclaimerScreen, setShowDisclaimerScreen] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [nowAsPercent, setNowAsPercent] = useState(0);

  const {isAuthenticated} = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
    if(isAuthenticated) {
      setAddedEmailList(true)
    }
  }, [isAuthenticated]);

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const calculateScore = () => {
    const partAScore = answers.slice(0, 6).reduce((score, answer) => score + (answer || 0), 0);
    const partBScore = answers.slice(6).reduce((score, answer) => score + (answer || 0), 0);
    return { partAScore, partBScore };
  };

  const allAnsweredOnPage = chunkedQuestions[currentPage].every(
    (question, index) => answers[index + currentPage * 5] !== null
  );

const calculateProgress = () => {
    const answered = answers.filter((answer) => answer !== null).length;
    return (answered / questions.length) * 100;
  };

  useEffect(() => {
    setNowAsPercent(Math.round(calculateProgress()));
  }, [answers]);

  const handleSubmit = () => {

    if (currentPage === chunkedQuestions.length - 1) {
      window.scrollTo(0, 0);
      submitQuizClick();
    } else {
      window.scrollTo(0, 0);
      setCurrentPage(currentPage + 1);
    }
  };

  const startAssessmentClick = () => {
    window.scrollTo(0, 0);
    setShowStartScreen(false);
    setShowDisclaimerScreen(true);
  };

  const acceptDisclaimerClick = () => {
    window.scrollTo(0, 0);
    setShowDisclaimerScreen(false);
    setShowQuiz(true);
  };

  const submitQuizClick = () => {
    window.scrollTo(0, 0);
    setResult(calculateScore());
    setShowQuiz(false);
    if (sessionStorage.getItem("addedEmailListASRS")) setAddedEmailList(true);
    setShowScore(true);
  };

  const previousPage = () => {
    window.scrollTo(0, 0);
    setCurrentPage(currentPage - 1);
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim() || !validateEmail(email)) {
      alert("Please enter a valid email");
      return;
    }
    try {
      const formData = new FormData();
      formData.append('email', email);

      await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/v1/users/email/subscribe`, formData);
      sessionStorage.setItem("addedEmailListASRS", true);
      setAddedEmailList(true);
    } catch {
      alert("Failed to subscribe. Please try again later.");
    }
  };

  return (
    <PageLayout>
      <HeroImage imagepath={topicsImageHeader} header="ADHD Assessment" subheader="Adult ADHD Self-Report Scale" pad='20px' />
      {showStartScreen && (
        <section style={{ width: "90%", margin: "5% auto", textAlign: "center" }}>
          <p>The Adult ADHD Self-Report Scale (ASRS) is a screening tool designed to help identify symptoms of adult ADHD. This assessment is widely used in clinical and research settings. It consists of 18 questions that assess common behaviors associated with ADHD.</p>
          <div style={{ margin: "5%" }}>
            <Button onClick={startAssessmentClick}>Start the assessment</Button>
            <p style={{ padding: "10px", margin: "0px" }}>-----OR-----</p>
            <Button disabled>Download (PDF Format)</Button>
          </div>
          <span style={{ fontSize: "0.8rem" }}>Source: Kessler et al., 2005, Psychological Medicine.</span>
        </section>
      )}
      {showDisclaimerScreen && (
        <section style={{ width: "90%", margin: "5% auto", textAlign: "center" }}>
          <p style={{ fontSize: "1.2rem" }}>Disclaimer!</p>
          <p>While the ASRS can offer valuable insights into whether someone exhibits traits consistent with ADHD, it is not a diagnostic tool. Please consult a healthcare professional for a comprehensive evaluation.</p>
          <Button onClick={acceptDisclaimerClick}>Acknowledge</Button>
          <p style={{ fontSize: "0.8rem" }}>By clicking "Acknowledge" you agree that you understand the above statement.</p>
        </section>
      )}
      {showQuiz && (
        <section style={{ width: "95%", margin: "10px auto" }}>
          <p>"1 - Never", "2- Rarely", "3 - Sometimes", "4 - Often", "5 - Very Often"</p>
          <ProgressBar now={nowAsPercent} label={`${nowAsPercent}%`} />
          <div>
            {chunkedQuestions[currentPage].map((question, index) => (
              <div key={index} style={{
                backgroundColor: index % 2 === 1 ? 'white' : '#0d6efd36',
                padding: '2%',
              }}>
                <p>{question.text}</p>
                <div className="w-100" style={{ maxWidth: "100%", margin: "auto" }}>
                  <ButtonGroup className="w-100">
                    {["1 - Never", "2", "3", "4", "5 - Very Often"].map((label, value) => (
                      <ToggleButton
                        key={value}
                        id={`radio-${index}-${value}`}
                        type="radio"
                        name={`question-${index + currentPage * 5}`}
                        variant="outline-primary"
                        value={value}
                        checked={answers[index + currentPage * 5] === value}
                        onChange={() => handleAnswerChange(index + currentPage * 5, value)}
                        className="flex-fill text-black py-3 px-0"
                        style={{ minWidth: "18%" }}
                      >
                        {label}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between", margin: "20px auto" }}>
              <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>Previous</Button>
              <Button onClick={handleSubmit} disabled={!allAnsweredOnPage}>{currentPage === chunkedQuestions.length - 1 ? "Submit" : "Next"}</Button>
            </div>
          </div>
        </section>
      )}
      {showScore && (
        <section style={{ textAlign: "center", marginTop: "20px",backgroundColor: "rgba(0,0,0,0.2)", padding: "10px" }}>
          <div><ProgressBar now={nowAsPercent} label={`${nowAsPercent}%`} /></div>
          <div style={{width: "90%", margin:"auto", padding:"10px", textAlign:"center"}}>
          {addedMailList ? (
          <div><h2>Your Score</h2>
          <ul>
            <li><strong>Part A Score:</strong> {result.partAScore}</li>
            <li><strong>Part B Score:</strong> {result.partBScore}</li>
            <li><strong>Total Score:</strong> {result.partAScore + result.partBScore}</li>
          </ul>
          <p>
            If your Part A Score is 4 or higher, it may indicate a strong likelihood of ADHD symptoms.
            Please consult a healthcare professional for further evaluation.
          </p></div>) : (
          <div>
            <h3>Your Score: ?</h3>
                <p>To receive your detailed results, please join our mailing list or create an account.</p>
                <form onSubmit={handleFormSubmit}>
                    <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </label><br />
                    <button type="submit">Submit</button>
                </form>
          </div>
          )}
          </div>
        </section>
      )}
    </PageLayout>
  );
};

export default ADHDTest;
