import { PageLayout } from "../../components/page-layout";
import HeroImage from "../../components/hero";
import topicsImageHeader from "../../static/backgroundHero.jpg";
import { Link } from "react-router-dom";

export default function Tools() {

  const tools = [
    { name: "AQ Assessment", desc:"", link: "/tool/aqtest" },
    { name: "ASRS Assessment", desc:"", link: "/tool/asrs" },
    { name: "Dyslexia Assessment", desc:"", link: "/tool/dst" },
    // Add more tools here as needed
  ];

  return (
    <PageLayout>
      <HeroImage imagepath={topicsImageHeader} header="SEN Tools" subheader="Explore all of the tools available at SENResource.com" pad='20px'></HeroImage>
      
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", padding: "0 5%", margin:"20px auto" }}>
        {tools.map((tool, index) => {

          const link = tool.link;
          return (
          <Link
          to={link} 
            key={index} 
            style={{ 
              border: "1px solid #ccc", 
              borderRadius: "8px", 
              padding: "20px", 
              minWidth: "200px", 
              textAlign: "center", 
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)" 
            }}
          >
            <p 
                style={{ 
                textDecoration: "none", 
                color: "inherit", 
                fontWeight: "bold", 
                fontSize: "18px" 
              }}
            >
              {tool.name}
            </p>
          </Link>)
})}
      </div>
    </PageLayout>
  );
  }