import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styling/ViewTopic.css";
import DisplayAllResources from "../../components/map_displays/displayAllResources";
import LoadingWheel from "../../components/loading";
import HeroImage from "../../components/hero";
import { PageLayout } from "../../components/page-layout";
import topicsImageHeader from "../../static/backgroundHero.jpg";
import { useParams } from "react-router-dom";

function ViewTopic() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [topicDesc, setTopicDesc] = useState("");
  const [topicId, setTopicId] = useState("");
  const [topicImage, setTopicImage] = useState(topicsImageHeader);

  useEffect(() => {
    const fetchTopicData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/${slug}`);
        const item = response.data;

        if (item.name !== topicName) setTopicName(item.name);
        if (item.description !== topicDesc) setTopicDesc(item.description);
        if (item._id !== topicId) setTopicId(item._id);
        setTopicImage(item.images?.[0]?.file || topicsImageHeader);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching topic data:", error);
        }
        setTopicName("");
        setTopicDesc("");
        setTopicImage(topicsImageHeader);
      } finally {
        setLoading(false);
      }
    };

    fetchTopicData();
  }, [slug]);

  if (loading) return <LoadingWheel />;

  return (
    <PageLayout>
      <div className="container-flex">
        <HeroImage imagepath={topicImage} header={topicName} subheader={topicDesc} pad="20px" />
        <br />
        <DisplayAllResources urlPassed={`/v1/items/topic/${topicId}`} dataPassed={true} />
      </div>
    </PageLayout>
  );
}

export default ViewTopic;
